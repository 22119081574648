<template>
  <b-card>
    <div id="container-xl" v-if="resource">
      <div id="left">
        <div>
          <b-img blank-color="#777" class="img-contain" src="https://media.istockphoto.com/photos/excavator-at-a-construction-site-against-the-setting-sun-picture-id143918313?b=1&k=20&m=143918313&s=170667a&w=0&h=DJ1aljI0gDG-5kSNctdA2jDBBcm3HW5lzW0b3Knx7cw=" alt="Transparent image"></b-img>
          <b-row class="mt-4">
            <b-col cols="3" >
              <b-img fluid src="@/assets/images/gallery/power.jpg" />
            </b-col>
            <b-col cols="3">
              <b-img fluid src="@/assets/images/gallery/power.jpg" />
            </b-col>
            <b-col cols="3">
              <b-img fluid src="@/assets/images/gallery/power.jpg" />
            </b-col>
          </b-row>
        </div>
      </div>
      <div id="middle">
        <h2>{{ resource.name }}</h2>
        <i class="fas fa-map-marker-alt"></i> <b>Dubai</b><br /><br />
        <h5>Product Details</h5>
        <b-row>
        <b-col cols="6"><p><b>Make:</b> {{ resource.make }}</p></b-col>
        <b-col cols="6"><p><b>Model:</b> {{ resource.model }}</p></b-col>
        <b-col cols="6"><p><b>Year:</b> {{ resource.year }}</p></b-col>
        <b-col cols="6"><p><b>Registration:</b> {{ resource.registration }}</p></b-col>
        <b-col cols="6"><p><b>Serial:</b> {{ resource.serial }}</p></b-col>
        <b-col cols="6"><p><b>Engine:</b> {{ resource.engine }}</p></b-col>
        <b-col cols="6"><p><b>Fuel:</b> {{ resource.fuel }}</p></b-col>
        <b-col cols="6"><p><b>Transmission:</b> {{ resource.transmission }}</p></b-col>
        <b-col cols="6"><p><b>Gross Weight:</b> {{ resource.gross_weight }}</p></b-col>
        <b-col cols="6"><p><b>Dimensions:</b> {{ resource.dimensions }}</p></b-col>
        <b-col cols="6"><p><b>Operation Speed:</b> {{ resource.operation_speed }}</p></b-col>
        <b-col cols="6"><p><b>Operation Capacity:</b> {{ resource.operation_capacity }}</p></b-col>
        <b-col cols="6"><p><b>Performance:</b> {{ resource.performance }}</p></b-col>
        <b-col cols="6"><p><b>Years (Used):</b> {{ resource.operation_years }}</p></b-col>
        <b-col cols="6"><p><b>Fitness Score:</b> {{ resource.fitness_score }}</p></b-col>
        <b-col cols="12"><h5>Pricing Details</h5></b-col>
        <b-col cols="6"><p><b>Selling Price:</b> {{ resource.purchase_price }}</p></b-col>
        <b-col cols="6"><p><b>Rental Price:</b> {{ resource.rental_price }}/{{ resource.rental_unit }}</p></b-col>
        <b-col cols="6"><p><b>Monthly Discount:</b> {{ resource.monthly_discount }}</p></b-col>
        <b-col cols="6"><p><b>Yearly Discount:</b> {{ resource.yearly_discount }}</p></b-col>
        <b-col cols="6"><p><b>Units:</b> {{ resource.quantity }}</p></b-col>
        <b-col cols="12"><h5>Product Details</h5></b-col>
        <b-col cols="6"><p><b>Fitness certificate:</b> {{ resource.fitness_certificate }}</p></b-col>
        <b-col cols="6"><p><b>Specification:</b> {{ resource.specification }}</p></b-col>
        <b-col cols="6"><p><b>Operation Specs:</b> {{ resource.operation_spec }}</p></b-col>
        <b-col cols="6"><p><b>Terms:</b> {{ resource.terms }}</p></b-col>
        <b-col cols="12"><h5>Product Detail Description</h5></b-col>
        <b-col cols="12"><p><b>Details:</b> {{ resource.comments }}</p></b-col>
        </b-row>
      </div>
      <div id="right">
      
      <div class="text-center bg-light">
        
        <div v-if="resource.product_type === 'rent'" class="d-flex flex-column p-4 mt-4">
          <h3>Select Dates</h3>
          <vc-date-picker 
            v-model="range" 
            :available-dates='{ start: startDate, end: endDate }'
            is-range 
            is-expanded />
          <!--<b-button variant="info"
             @click="checkDate()">Request Booking</b-button
          >-->
        </div>

        <div v-if="isAuthenticated && resource.product_type === 'sale'" class="d-flex flex-column px-4 mt-4">
          <h3>DHs {{ resource.purchase_price }}</h3>
          <span>+ {{ resource.maintenance_cost }}</span>
          <b-button variant="warning" @click="triggerPopup('quote')">Make an Offer</b-button
          >
        </div>
        <div v-if="isAuthenticated && resource.product_type === 'rent'" class="d-flex flex-column px-4 mt-4">
          <span>{{ dateMsg }}</span>
          <h3>{{ resource.rental_price }}/{{ resource.rental_unit }}</h3>
          <span>+ {{ resource.maintenance_cost }}</span>
          <b-button v-if="dateValid" variant="warning"
             @click="triggerPopup('quote')">Request Booking</b-button
          >
          <b-button v-else variant="warning" disabled>Request Booking</b-button
          >
        </div>
        <div class="d-flex flex-column p-4 mt-4">
          <h4 v-if="resource.inspection_status === '0'" class="text-center">Not Sure!<br> you can order our inspection service by a qualified inspector to be sure about this!</h4>
          <h4 v-if="resource.inspection_status === '1'" class="text-center">Inspection in Progress!<br> Please check back later to view its inspection report!</h4>
          <h4 v-if="resource.inspection_status === '2'" class="text-center">Congratulations!<br> This resource has its inspection report available!</h4>
          <b-button v-if="resource.inspection_status === '0'" variant="primary" @click="addInspReq()" :disabled="resource.inspection_status === 0">Get It Inspected</b-button
          >
        </div>
      </div>

      </div>

    <!--<div id="container-xl">
      <div id="left2" class="text-center bg-light">
        
          <br>
          <h6>Submit a Review</h6><br>
          <div style="margin-left: 45px">
          <b-input placeholder="Your Name" style="width:150px" type="text" class=" shadow"></b-input><br>
          <b-input placeholder="Write your review.." type="text" style="width: 150px" class="shadow"></b-input><br><br>
        </div>
      </div>
      <div id="middle2" style="margin-left: 5px" class=" text-center bg-light">
        <h5>Customer Reviews</h5>
        <div class="bg-grey text-center">
          Review
          <br>
          ..<br>
          ..<br>
          ..<br>
          ..<br>
          ..<br>
        </div>
        <b-pagination style="margin-left:2.7in"></b-pagination>
      </div>
    </div>-->

    <div class="popup border-popup" v-if="showPopupQuote==true">
      <div class="popup-inner text-center">
        <b-button-close @click="triggerPopup('close')"></b-button-close>
        <h3>Send Offer</h3><br>
        
        <b-col cols="6"><p><b>Mobilization Time:</b> {{ resource.op_ready_time }} Hrs</p></b-col>
        <b-col cols="6"><p><b>Mobilization Cost:</b> {{ resource.maintenance_cost }}</p></b-col>
        <b-form inline>
          <div>
            <b-input type="text" v-model="form.offerred_price" placeholder="Offered Price"></b-input>
            <b-input type="text" v-model="form.quantity" placeholder="Quantity" style="margin-left:40px"></b-input><br><br>
            <b-input type="text" style="width:450px" v-model="form.order_comments" placeholder="I am interested in buying the vehicle. Please Review my Offer?"></b-input><br>
            
            <br><b-button style="width:120px" variant="primary" @click="submitOrder()">Submit</b-button><br><br>
            <a href="#" style="color:green"><b><u>Login or Create a free account to send direct offers</u></b></a>
          </div>
        </b-form>
      </div>
	</div>

    </div>
  </b-card>
</template>
<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
export default {
  data: () => ({
    mainProps: {
          center: false,
          fluidGrow: false,
          blank: true,
          blankColor: '#bbb',
          width: 400,
          height: 400,
          class: ''
    },
    dateMsg: "",
    showSingle: false,
    showMultiple: false,
    showPopupQuote: false,
    resource: null,
    form: {
      order_type: null,
      resource_id: null,
      user_id: null,
      offerred_price: null,
      quantity: null,
      payment_status: 0,
      payment_method: "Default",
      order_date: null,
      order_comments: null,
      order_status: 0,
      inspection_status:0
    },
    range:{
      start: new Date(),
      end: null
    },
    startDate: null,
    endDate: null,
    weeklyoffs: [],
    availability: [],
    dateValid: false,
    avalOptions: [
      { text: "Single", value: "single"},
      { text: "Multiple", value: "multiple"}
    ],
    inspRequest: {
      user_id:null,
      location_id:1,
      product_status_id:2,
      category_id:2,
      calendar_template_id:1,
      name:"No Name",
      registration:"None",
      rate_price:0,
      rate_unit:0,
      monthly_discount:0,
      yearly_discount:0,
      experience_years:0,
      success_score:0,
      certificate:"None",
      specification:"None",
      terms:"None",
      comments:"None",
      resource_id:null,
      inspection_date:"",
      inspection_status:0
    }
  }),
  watch: {},
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
      authEmail: (state) => state.auth.authEmail,
      users: (state) => state.auth.users,
      authId: (state) => state.auth.authId
    })
  },
  mounted() {
    this.getResource({ userid: this.$route.params.uid, id: this.$route.params.id }).then(res =>{
      this.resource = res[0];
      this.checkDate();
    });
  },
  methods: {
    ...mapActions(["getResource", "updateResource", "getAvailability", "saveOrder", "saveInspectionJob"]),
    submitOrder(){
      this.form.resource_id = this.resource.id;
      this.form.user_id = this.authId;
      this.form.order_type = this.resource.product_type;
      this.form.order_date = moment().format("YYYY-MM-DD hh:mm:ss");
      
      this.saveOrder(this.form).then(res => {
        if(res && 'status' in res && res.status >= 400){
          this.$bvToast.toast(res.data, {
            title: "Something Went Wrong",
            variant: 'danger',
            solid: true
          })
        }
        else{
          this.triggerPopup("close");
          this.$bvToast.toast(res.data, {
            title: "Success",
            variant: 'success',
            solid: true
          })
        }
      })
    },
    checkDate(){
      this.startDate = new Date();
      let endDate = new Date(this.startDate);
      endDate.setDate(this.startDate.getDate() + 60);
      this.endDate = endDate;
      this.getAvailability({resource_id: this.resource.id, start_date: this.startDate, end_date: this.endDate}).then((res) => {
        /*this.dateMsg = "";
        this.dateValid = true;
        if(res === "Date is valid"){
          this.dateMsg = "Available";
          this.dateValid = true
        }
        if('status' in res && res.status !== 200){
          this.dateMsg = "Not Available";
          this.dateValid = false
        }*/
        this.startDate = res.data.dates[0].dated;
        this.endDate = res.data.dates[res.data.dates.length-1].dated;
        res.data.weekly_off_days.foreach(d => {
          console.log(d);
          let day = 0;
          if(d.day === 'sunday') {
            day = 1
          }
          if(d.day === 'monday') {
            day = 2
          }
          if(d.day === 'tuesday') {
            day = 3
          }
          if(d.day === 'wednesday') {
            day = 4
          }
          if(d.day === 'thursday') {
            day = 5
          }
          if(d.day === 'friday') {
            day = 6
          }
          if(d.day === 'saturday') {
            day = 7
          }
          this.weeklyoffs.push(day);
        })
      })
    },
    addInspReq(){
      this.inspRequest.resource_id = this.resource.id;
      this.inspRequest.user_id = this.authId;
      this.inspRequest.inspection_date = moment().format("YYYY-MM-DD");
      this.saveInspectionJob(this.inspRequest).then(() => {
        this.resource.inspection_status = 1;
        this.updateResource(this.resource).then((res) => {
          this.$bvToast.toast(res.data, {
            title: "Success",
            variant: 'success',
            solid: true
          })
        })
      })
    },
    toggle(label) {
      if (label == "single") {
        this.showSingle = true;
        this.showMultiple = false;
      }
      if (label == "multiple") {
        this.showSingle = false;
        this.showMultiple = true;
      }
    },
    triggerPopup(label2){
      if(label2=="quote"){
        this.showPopupQuote=true;
      }
      if(label2=="close"){
        this.showPopupQuote = false;
      }
    }
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
#container {
  height: 100%;
  width: 100%;
  font-size: 0;
}
#left,
#middle,
#right {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  font-size: 12px;
}
#left {
  width: 35%;
}
#left .img-contain{
  width: 90%;
}
#middle {
  width: 35%;
}
#right {
  width: 29%;
}
#left2,
#middle2,
#right2 {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  font-size: 12px;
}
#left2 {
  width: 20%;
}
#middle2 {
  width: 55%;
}
.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.2);
	
	display: flex;
	align-items: center;
	justify-content: center;
}
	.popup-inner {
		background: #FFF;
		padding: 32px;
	}
</style>